import { defer } from "react-router-dom";
import { get } from "../../api/laravelApi";
import {
  roomsOverviewParamsEndpoint,
  roomsOverviewSummaryEndpoint,
  roomsOverviewTableEndpoint,
} from "../../constants";

export const RoomsOverviewLoader = async ({ request }) => {
  const baseUrl = new URL(request.url);
  const customerIds = baseUrl.searchParams.getAll("customersIds");
  const facilityIds = baseUrl.searchParams.getAll("facilitiesIds");
  const unitIds = baseUrl.searchParams.getAll("unitsIds");
  const unitTypeIds = baseUrl.searchParams.getAll("UnitTypesIds");
  const startDate = baseUrl.searchParams.get("startDate");
  const endDate = baseUrl.searchParams.get("endDate");

  let params = {
    customerIds,
    facilityIds,
    unitIds,
    unitTypeIds,
    startDate,
    endDate,
  };

  if (Object.values(params).every((param) => !param || !param.length)) {
    return get(roomsOverviewParamsEndpoint, params)
      .then((resp) => {
        return [
          {
            customerIds: resp.params.customer_ids.value,
            facilityIds: resp.params.facility_ids.value,
            unitIds: resp.params.unit_ids.value,
            unitTypeIds: resp.params.tag_ids.value,
            startDate: resp.params.date_range.value[1],
            endDate: resp.params.date_range.value[2],
          },
          resp,
        ];
      })
      .then(([params, resp]) => {
        const summary = get(roomsOverviewSummaryEndpoint, params);
        const roomsTable = get(roomsOverviewTableEndpoint, params);

        return defer({
          summary,
          roomsTable,
          filters: resp,
        });
      });
  }

  const paramsForDataEndpoints = Object.keys(params).reduce((acc, key) => {
    // This is a temporary solution. The params endpoint uses an older interface
    // and expects a 'null' string to be passed instead of omitting the key.
    if (params[key]?.length && params[key][0] !== "null") {
      acc[key] = params[key];
    }
    return acc;
  }, {});

  const filters = get(roomsOverviewParamsEndpoint, params);
  const summary = get(roomsOverviewSummaryEndpoint, paramsForDataEndpoints);
  const roomsTable = get(roomsOverviewTableEndpoint, paramsForDataEndpoints);

  return defer({
    summary,
    roomsTable,
    filters,
  });
};
