import { StaffOverview } from "../../model/types";
import { convertSecondsToHoursMinutesSeconds } from "../../utils";

// if the number of visits is 0 then show something differenent than if most visited by info is just missing
// also need to handle case of how unit_ids cannot be just the string null in the new pattern

type StaffOverviewTableData = {
  staffName: string;
  visitLengthAvg: string;
  numberOfVisits: number;
  longestVisit: string;
  mostVisitedRoom: string;
};

export function transformStaffOverview(
  staffOverview: StaffOverview
): StaffOverviewTableData {
  const mostVisitedRoom = staffOverview.mostVisitedRoom?.name ?? "N/A";

  return {
    staffName: `${staffOverview.user.firstName} ${staffOverview.user.lastName}`,
    visitLengthAvg: convertSecondsToHoursMinutesSeconds(
      staffOverview.visitLengthAvg
    ),
    numberOfVisits: staffOverview.visitCount || 0,
    longestVisit: convertSecondsToHoursMinutesSeconds(
      staffOverview.longestVisit
    ),
    mostVisitedRoom,
  };
}
