import { defaultDateRange, userAccessEndpoint } from "../constants";
import { formatDate } from "../utils";

type BaseUrlParams = {
  startDate?: string | null;
  endDate?: string | null;
  customerIds?: string[] | null;
  facilityIds?: string[] | null;
  roomIds?: string[] | null;
  unitIds?: string[] | null;
  exposureType?: string[] | null;
  shiftSelector?: string | null;
  unitTypeIds?: string[] | null;
  aggregation?: string | null;
};

export interface HandHygieneUrlParams extends BaseUrlParams {
  groupIds?: string[] | null;
  sumOverTimeRange?: boolean | null;
  user_ids_filter?: string | null;
}

export interface AssetTrackingUrlParams extends BaseUrlParams {
  typeIds?: string[] | null;
  modelIds?: string[] | null;
}

export interface NursingInsightsUrlParams extends BaseUrlParams {
  roomIds?: string[] | null;
}

type AnyUrlParams =
  | HandHygieneUrlParams
  | NursingInsightsUrlParams
  | AssetTrackingUrlParams;

function includeDateRangeParams(
  params: AnyUrlParams
): params is { startDate: string; endDate: string } {
  return !!params.startDate && !!params.endDate;
}

function includeSumOverTimeRange(
  params: AnyUrlParams
): params is { sumOverTimeRange: boolean } {
  return "sumOverTimeRange" in params && !!params.sumOverTimeRange;
}

function includeAggregation(
  params: AnyUrlParams
): params is { aggregation: string } {
  return "aggregation" in params && !!params.aggregation;
}

function includeUserIdsFilter(
  params: AnyUrlParams
): params is { user_ids_filter: string } {
  return (
    "user_ids_filter" in params &&
    (!!params.user_ids_filter || params.user_ids_filter === "")
  );
}

function includeGroupIdsFilter(
  params: AnyUrlParams
): params is { groupIds: string[] } {
  return (
    "groupIds" in params && !!params.groupIds && params.groupIds.length > 0
  );
}

function includeTypeIdsFilter(
  params: AnyUrlParams
): params is { typeIds: string[] } {
  return "typeIds" in params && !!params.typeIds && params.typeIds.length > 0;
}

function includeModelIdsFilter(
  params: AnyUrlParams
): params is { modelIds: string[] } {
  return (
    "modelIds" in params && !!params.modelIds && params.modelIds.length > 0
  );
}

export async function get<T extends BaseUrlParams>(
  endpoint: string,
  urlParams: T | null = null
) {
  let response;
  let searchParams = new URLSearchParams();

  if (!urlParams) {
    const defaultParams = {
      date_range_filter: `DD|${formatDate(
        defaultDateRange.startDate
      )}|${formatDate(defaultDateRange.endDate)}`,
    };
    searchParams = new URLSearchParams(defaultParams);
  } else {
    if (includeDateRangeParams(urlParams))
      searchParams.append(
        "date_range_filter",
        `DD|${urlParams.startDate}|${urlParams.endDate}`
      );

    if (includeSumOverTimeRange(urlParams))
      searchParams.append("sum_over_time_range_filter", "1");

    if (includeAggregation(urlParams))
      searchParams.append("aggregation_filter", urlParams.aggregation);

    if (includeUserIdsFilter(urlParams)) {
      searchParams.append("user_ids_filter", urlParams.user_ids_filter);
      if (urlParams.user_ids_filter !== "") {
        searchParams.append("sum_over_time_range_filter", "0");
      }
    }

    if (urlParams.customerIds && urlParams.customerIds.length > 0) {
      if (urlParams.customerIds[0] === "null") {
        searchParams.append("customer_ids_filter", "null");
      } else {
        for (let i = 0; i < urlParams.customerIds.length; i++) {
          searchParams.append(
            "customer_ids_filter[]",
            urlParams.customerIds[i]
          );
        }
      }
    }

    if (urlParams.shiftSelector && urlParams.shiftSelector.length > 0) {
      if (urlParams.shiftSelector[0] === "null") {
        searchParams.append("blueprint_realtime_shift_selector_filter", "null");
      } else {
        for (let i = 0; i < urlParams.shiftSelector.length; i++) {
          searchParams.append(
            "blueprint_realtime_shift_selector_filter[]",
            urlParams.shiftSelector[i]
          );
        }
      }
    }

    if (urlParams.facilityIds && urlParams.facilityIds.length > 0) {
      if (urlParams.facilityIds[0] === "null") {
        searchParams.append("facility_ids_filter", "null");
      } else {
        for (let i = 0; i < urlParams.facilityIds.length; i++) {
          searchParams.append(
            "facility_ids_filter[]",
            urlParams.facilityIds[i]
          );
        }
      }
    }

    if (urlParams.roomIds && urlParams.roomIds.length > 0) {
      if (urlParams.roomIds[0] === "null") {
        searchParams.append("room_config_ids_filter", "null");
      } else {
        for (let i = 0; i < urlParams.roomIds.length; i++) {
          searchParams.append("room_config_ids_filter[]", urlParams.roomIds[i]);
        }
      }
    }

    if (urlParams.unitIds && urlParams.unitIds.length > 0) {
      if (urlParams.unitIds[0] === "null") {
        searchParams.append("unit_ids_filter", "null");
      } else {
        for (let i = 0; i < urlParams.unitIds.length; i++) {
          searchParams.append("unit_ids_filter[]", urlParams.unitIds[i]);
        }
      }
    }

    if (urlParams.unitTypeIds && urlParams.unitTypeIds.length > 0) {
      if (urlParams.unitTypeIds[0] === "null") {
        searchParams.append("tag_ids_filter", "null");
      } else {
        for (let i = 0; i < urlParams.unitTypeIds.length; i++) {
          searchParams.append("tag_ids_filter[]", urlParams.unitTypeIds[i]);
        }
      }
    }

    if (urlParams.exposureType && urlParams.exposureType.length > 0) {
      if (urlParams.exposureType[0] === "") {
        searchParams.append("exposure_type_filter", "");
      } else {
        for (let i = 0; i < urlParams.exposureType.length; i++) {
          searchParams.append(
            "exposure_type_filter",
            urlParams.exposureType[i]
          );
        }
      }
    }

    if (includeGroupIdsFilter(urlParams)) {
      if (urlParams.groupIds[0] === "null") {
        searchParams.append("group_ids_filter", "null");
      } else {
        for (let i = 0; i < urlParams.groupIds.length; i++) {
          searchParams.append("group_ids_filter[]", urlParams.groupIds[i]);
        }
      }
    }

    if (includeTypeIdsFilter(urlParams)) {
      if (urlParams.typeIds[0] === "null") {
        searchParams.append("asset_type_ids_filter", "null");
      } else {
        for (let i = 0; i < urlParams.typeIds.length; i++) {
          searchParams.append("asset_type_ids_filter[]", urlParams.typeIds[i]);
        }
      }
    }

    if (includeModelIdsFilter(urlParams)) {
      if (urlParams.modelIds[0] === "null") {
        searchParams.append("asset_model_ids_filter", "null");
      } else {
        for (let i = 0; i < urlParams.modelIds.length; i++) {
          searchParams.append(
            "asset_model_ids_filter[]",
            urlParams.modelIds[i]
          );
        }
      }
    }
  }

  const url = `${process.env.REACT_APP_AUTH_DOMAIN}/${endpoint}?${searchParams}`;

  response = await fetch(url, {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "x-swipesense-platform": "spa",
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.assign(
        process.env.REACT_APP_AUTH_DOMAIN +
          "?redirectAddress=" +
          window.location.href +
          "&_storeSourceAsIntendedUrl=true"
      );
      throw new Response("Expired", { status: 401 });
    } else if (response.status === 403) {
      throw new Response("Forbidden", { status: 403 });
    } else {
      throw new Error("Could not fetch data");
    }
  } else {
    return response.json();
  }
}

export async function getSigned(
  uuid: string,
  expires: string | null,
  signature: string
) {
  let expiration;

  if (expires) {
    expiration = "expires=" + expires + "&";
  }

  const url = `${process.env.REACT_APP_AUTH_DOMAIN}/report/s/${uuid}?${expiration}signature=${signature}`;

  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "x-swipesense-platform": "spa",
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.assign(
        process.env.REACT_APP_AUTH_DOMAIN +
          "?redirectAddress=" +
          window.location.href +
          "&_storeSourceAsIntendedUrl=true"
      );
    } else if (response.status === 403) {
      throw new Response("Forbidden", { status: 403 });
    } else {
      throw new Error("Could not fetch data");
    }
  } else {
    return response.json();
  }
}

interface PostParams {
  body?: any;
  url: string;
  additionalHeaders?: { [key: string]: string };
}

export async function post(params: PostParams) {
  const { body, url, additionalHeaders } = params;
  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "x-swipesense-platform": "spa",
    ...additionalHeaders,
  };

  const response = await fetch(url, {
    body: JSON.stringify(body),
    method: "POST",
    credentials: "include",
    headers,
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.assign(
        process.env.REACT_APP_AUTH_DOMAIN +
          "?redirectAddress=https://" +
          process.env.REACT_APP_ANALYTICS_DOMAIN +
          "&_storeSourceAsIntendedUrl=true"
      );
    } else if (response.status === 403) {
      throw new Response("Forbidden", { status: 403 });
    } else {
      throw new Error("Could not fetch data");
    }
  } else {
    if (response.status === 204) {
      return response;
    }

    return response.json();
  }
}

interface PatchParams {
  body?: any;
  url: string;
  additionalHeaders?: { [key: string]: string };
}

export async function patch(params: PatchParams) {
  const { body, url, additionalHeaders } = params;
  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "x-swipesense-platform": "spa",
    ...additionalHeaders,
  };

  const response = await fetch(url, {
    body: JSON.stringify(body),
    method: "PATCH",
    credentials: "include",
    headers,
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.assign(
        process.env.REACT_APP_AUTH_DOMAIN +
          "?redirectAddress=https://" +
          process.env.REACT_APP_ANALYTICS_DOMAIN +
          "&_storeSourceAsIntendedUrl=true"
      );
    } else if (response.status === 403) {
      throw new Response("Forbidden", { status: 403 });
    } else {
      throw new Error("Could not fetch data");
    }
  } else {
    if (response.status === 204) {
      return response;
    }

    return response.json();
  }
}

export async function deleteMethod(params) {
  const { body, url, additionalHeaders } = params;
  const headers = {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "x-swipesense-platform": "spa",
    ...additionalHeaders,
  };

  const response = await fetch(url, {
    body: JSON.stringify(body),
    method: "DELETE",
    credentials: "include",
    headers,
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.assign(
        process.env.REACT_APP_AUTH_DOMAIN +
          "?redirectAddress=https://" +
          process.env.REACT_APP_ANALYTICS_DOMAIN +
          "&_storeSourceAsIntendedUrl=true"
      );
    } else if (response.status === 403) {
      throw new Response("Forbidden", { status: 403 });
    } else {
      throw new Error("Could not fetch data");
    }
  } else {
    if (response.status === 204) {
      return response;
    }

    return response.json();
  }
}

export async function fetchUserInfo() {
  return await get(userAccessEndpoint);
}
