import { useAsyncValue } from "react-router";
import { StaffOverviewStaffTableDTO } from "../../model/types";
import Table from "../Table/Table";
import { transformStaffOverview } from "../../transformers/visitOverview/transformStaffOverview";

function StaffTable() {
  const tableData = useAsyncValue() as StaffOverviewStaffTableDTO;
  const { staffOverviews } = tableData;

  const columns = [
    {
      name: "staffName",
      label: "Staff Name",
    },
    {
      name: "visitLengthAvg",
      label: "Average Length of Visit",
    },
    {
      name: "numberOfVisits",
      label: "Number of Visits",
    },
    {
      name: "longestVisit",
      label: "Longest Visit",
    },
    {
      name: "mostVisitedByUser",
      label: "Most Visited By",
    },
  ];

  const data = staffOverviews.map(transformStaffOverview);

  return <Table title={"Staff Overview"} data={data} columns={columns}></Table>;
}

export default StaffTable;
